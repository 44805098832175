

export const TYPES = {
    ALL: "ALL",
    REQUESTED: "REQUESTED",
    EREEN: "EREEN",
    IRSEN: "IRSEN",
    CONFIRM: "CONFIRM",
    REJECTED: "REJECTED",
    DELETE: "DELETE",
    APPROVED: "APPROVED",
}


export const LEASING_TYPES = {
    N: "N",
    Y: "Y",
}